import { Institution } from "../../logic/appState.ts";
import { getStore } from "../store/getStore.ts";
import { DoubleArrowRightIcon } from "@radix-ui/react-icons";
import { Card, CardContent } from "../shared/card.tsx";
import { Avatar, AvatarFallback, AvatarImage } from "../shared/avatar.tsx";

export function InstitutionListItem({ institution }: { institution: Institution }) {
  return (
    <Card
      key={institution.id}
      data-testid={"connect-button"}
      onClick={() => {
        getStore().dispatch("didTapInstitutionListItem", { institutionId: institution.id });
      }}
      className="backdrop-blur-lg bg-white bg-opacity-50 hover:bg-opacity-70 transition-all duration-300 shadow-md hover:shadow-lg"
    >
      <CardContent className="p-3 w-full">
        <div className={"grid grid-cols-12 flex-grow items-center"}>
          <div className={"col-span-6 overflow-scroll whitespace-nowrap mr-1"}>{institution.name}</div>
          <div className={"col-span-2 overflow-scroll whitespace-nowrap"}>{institution.countries.join(", ")}</div>
          <div className={"col-span-3 overflow-scroll"}>
            <Avatar className="m-auto h-8 w-8">
              <AvatarImage src={institution.logoUrl} alt={institution.name} />
              <AvatarFallback>{institution.name}</AvatarFallback>
            </Avatar>
          </div>
          <div className={"col-span-1 mx-auto"}>
            <DoubleArrowRightIcon />
          </div>
        </div>
      </CardContent>
    </Card>
  );
}
