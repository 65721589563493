import { AppState, Transaction } from "./appState.ts";

function selectedInstitutionFilter(t: Transaction, s: AppState): boolean {
  return (
    s.home.filters.selectedInstitutions.length === 0 ||
    s.home.filters.selectedInstitutions.find((i) => i.id === t.institution.id) !== undefined
  );
}

function dateRangeFilter(t: Transaction, s: AppState): boolean {
  const matchFrom = s.home.filters.dateFrom === null || s.home.filters.dateFrom <= t.date;
  const matchTo = s.home.filters.dateTo === null || s.home.filters.dateTo >= t.date;
  return matchFrom && matchTo;
}

export function getFilteredTransactions(state: AppState): Transaction[] {
  return Object.values(state.transactions.data)
    .filter((t) => selectedInstitutionFilter(t, state) && dateRangeFilter(t, state))
    .sort((a, b) => b.date.valueOf() - a.date.valueOf());
}
