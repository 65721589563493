import { useStore } from "./store/useStore.ts";
import { Card, CardContent } from "./shared/card.tsx";
import { Avatar, AvatarFallback, AvatarImage } from "./shared/avatar.tsx";
import { Button } from "./shared/button.tsx";
import { ChevronLeft, Plus } from "lucide-react";

export function ConnectedInstitutionScreen() {
  const [instutition, dispatch] = useStore((state) => state.institutions.connected);
  return (
    <div className="bg-gradient-to-br from-gray-100 to-gray-200 py-8 px-4 sm:px-6 lg:px-8 h-full">
      <ChevronLeft
        className={"text-gray-700 mb-4"}
        onClick={() => {
          dispatch("didTapBackButton");
        }}
      />
      <div className="text-3xl mb-6 bg-gradient-to-r from-purple-600 to-pink-600 text-transparent bg-clip-text">
        Connections
      </div>
      <div className={"mb-6"}>
        <div className="flex justify-between items-center mb-4">
          <Button
            onClick={() => {
              dispatch("didTapAddInstitutionButton");
            }}
            variant="outline"
          >
            <Plus className="mr-2 h-4 w-4" />
            Add new institution
          </Button>
        </div>
        <div className="max-w-4xl mx-auto">
          <div className="space-y-3">
            {instutition.map((i) => {
              return (
                <Card
                  key={i.id}
                  className="backdrop-blur-lg bg-white bg-opacity-50 hover:bg-opacity-70 transition-all duration-300 shadow-md hover:shadow-lg"
                >
                  <CardContent className="p-3">
                    <div className={"flex gap-1 items-center"}>
                      <Avatar className={"w-4 h-4"}>
                        <AvatarImage src={i.logoUrl} />
                        <AvatarFallback>{i.name}</AvatarFallback>
                      </Avatar>
                      <div>{i.name}</div>
                    </div>
                  </CardContent>
                </Card>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
