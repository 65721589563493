export interface Institution {
  name: string;
  logoUrl: string;
  id: string;
  countries: string[];
}

export interface Transaction {
  id: string;
  requisitionId: string;
  amount: string;
  date: Date;
  description: string;
  source: string;
  status: "booked" | "pending";
  category: string;
  institution: {
    name: string;
    logoUrl: string;
    id: string;
  };
}

export interface AppState {
  config: {
    googleSignIn: {
      buttonId: string;
    };
  };
  auth: {
    email: string | null;
  };
  transactions: {
    loading: boolean;
    data: Record<string, Transaction>;
  };
  institutions: {
    connected: Institution[];
    data: Institution[];
    loading: boolean;
    query: string;
  };
  home: {
    filters: {
      isExpanded: boolean;
      selectedInstitutions: Institution[];
      dateFrom: Date | null;
      dateTo: Date | null;
    };
  };
}

export function getInitialState(): AppState {
  return {
    config: { googleSignIn: { buttonId: "google-sign-in" } },
    institutions: { connected: [], data: [], loading: false, query: "" },
    transactions: { data: {}, loading: false },
    auth: {
      email: null,
    },
    home: {
      filters: {
        selectedInstitutions: [],
        isExpanded: false,
        dateFrom: null,
        dateTo: null,
      },
    },
  };
}
