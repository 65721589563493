import { getStore } from "./getStore.ts";
import { useSyncExternalStoreWithSelector } from "use-sync-external-store/with-selector";
import isEqual from "lodash.isequal";
import { AppState } from "../../logic/appState.ts";

export function useStore<TSelection>(selector: (state: AppState) => TSelection) {
  const store = getStore();
  const state = useSyncExternalStoreWithSelector(store.subscribe, store.getState, null, selector, isEqual);
  return [state, store.dispatch] as const;
}
