import { RouterProvider, RouterProviderProps } from "react-router-dom";
import { HomeScreen } from "./home/HomeScreen.tsx";
import { InstitutionScreen } from "./institutions/InstitutionScreen.tsx";
import { ErrorScreen } from "./error/ErrorScreen.tsx";
import { LoginScreen } from "./login/LoginScreen.tsx";
import { ConnectedInstitutionScreen } from "./connected-institution-screen.tsx";

export const routerConfig = [
  {
    path: "/",
    element: <HomeScreen />,
  },
  {
    path: "institutions",
    element: <InstitutionScreen />,
  },
  {
    path: "my-institutions",
    element: <ConnectedInstitutionScreen />,
  },
  {
    path: "login",
    element: <LoginScreen />,
  },
  {
    path: "error",
    element: <ErrorScreen />,
  },
];

export function App(config: { router: RouterProviderProps["router"] }) {
  return <RouterProvider router={config.router} />;
}
