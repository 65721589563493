import { InstitutionListItem } from "./InstitutionListItem.tsx";
import { useStore } from "../store/useStore.ts";

export function InstitutionsList() {
  const [state] = useStore((s) => {
    const filteredInstitutions = s.institutions.data.filter((institution) =>
      institution.name.includes(s.institutions.query),
    );
    return {
      loading: s.institutions.loading,
      institutions: filteredInstitutions,
    };
  });
  return (
    <div className={"space-y-3"}>
      {state.institutions.map((institution) => {
        return <InstitutionListItem key={institution.id} institution={institution} />;
      })}
    </div>
  );
}
