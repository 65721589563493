import { useEffect } from "react";
import { useStore } from "../store/useStore.ts";

export function LoginScreen() {
  const [googleSignInButtonId, dispatch] = useStore((s) => s.config.googleSignIn.buttonId);

  useEffect(() => {
    dispatch("onLoginScreenMount");
    return () => {
      dispatch("onLoginScreenUnmount");
    };
  }, []);

  return <div data-testid={"login-button"} id={googleSignInButtonId}></div>;
}
