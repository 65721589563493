import { useEffect } from "react";
import { getStore } from "../store/getStore.ts";
import { InstitutionsList } from "./InstitutionsList.tsx";
import { Input } from "../shared/input.tsx";
import { ChevronLeft, Search } from "lucide-react";
import { useStore } from "../store/useStore.ts";

export function InstitutionScreen() {
  const [query, dispatch] = useStore((state) => state.institutions.query);

  useEffect(() => {
    getStore().dispatch("onInstitutionScreenMount");
  }, []);

  return (
    <div className="bg-gradient-to-br from-gray-100 to-gray-200 py-8 px-4 sm:px-6 lg:px-8">
      <ChevronLeft
        className={"text-gray-700 mb-4"}
        onClick={() => {
          dispatch("didTapBackButton");
        }}
      />
      <div className="text-3xl mb-6 bg-gradient-to-r from-purple-600 to-pink-600 text-transparent bg-clip-text">
        Available banks
      </div>
      <div className="relative mb-4">
        <Search className="absolute w-4 left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
        <Input
          type="text"
          placeholder="Search for banks..."
          className="pl-10 pr-4 py-2 w-full bg-white"
          value={query}
          onChange={(e) => {
            dispatch("didChangeInstitutionsQuery", { query: e.target.value });
          }}
        />
      </div>
      <InstitutionsList />
    </div>
  );
}
