import { Draft, produce } from "immer";
import { BehaviorSubject } from "rxjs";

// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
export type Action<TKey extends string, TPayload = void> = TPayload extends void
  ? { key: TKey }
  : {
      key: TKey;
      payload: TPayload;
    };

type ExtractPayload<TAction, TKey> = TAction extends {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: any;
  key: TKey;
}
  ? TAction["payload"]
  : never;

type WithoutPayload<T> = T extends { payload: unknown } ? never : T;
type WithPayload<T> = T extends { payload: unknown } ? T : never;

export interface Dispatcher<TAction extends Action<string>> {
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-parameters
  <TKey extends WithoutPayload<TAction>["key"]>(key: TKey): void;

  // eslint-disable-next-line @typescript-eslint/unified-signatures
  <TKey extends WithPayload<TAction>["key"]>(key: TKey, payload: ExtractPayload<TAction, TKey>): void;
}

export type Effect<TAction extends Action<string>, TContext> = (
  params: {
    dispatch: Dispatcher<TAction>;
  } & TContext,
) => Promise<void> | void;

type ExtractAction<TAction, Key> = TAction extends { key: Key } ? TAction : never;
export type Reducer<TState, TAction extends Action<string>, TActionOut extends Action<string>, TContext> = {
  // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
  [Key in TAction["key"]]: (s: Draft<TState>, a: ExtractAction<TAction, Key>) => Effect<TActionOut, TContext> | void;
};

export interface Store<TState, TAction extends Action<string>> {
  dispatch: Dispatcher<TAction>;
  subscribe: (listener: (state: TState) => void) => () => void;
  getState: () => TState;
  destroy: () => void;
}

export function StoreLive<TState, TAction extends Action<string>, TContext>(params: {
  reducer: Reducer<TState, TAction, TAction, TContext>;
  initialState: TState;
  effectContext: TContext;
}): Store<TState, TAction> {
  const state = new BehaviorSubject<TState>(params.initialState);
  let blockPendingEffects = false;

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-parameters
  function dispatch<T extends WithoutPayload<TAction>>(key: T["key"]): void;
  function dispatch<T extends WithPayload<TAction>>(key: T["key"], payload: T["payload"]): void;
  function dispatch(key: string, payload?: unknown): void {
    state.next(
      produce(state.value, (mutableState) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/no-unsafe-argument
        const effect = params.reducer[key as TAction["key"]](mutableState, { key, payload } as any);
        if (effect) {
          setTimeout(() => {
            if (!blockPendingEffects) {
              void effect({ dispatch, ...params.effectContext });
            }
          });
        }
      }),
    );
  }

  function subscribe(listener: (value: TState) => void) {
    const subscription = state.subscribe(listener);
    return () => {
      subscription.unsubscribe();
    };
  }

  function getState(): TState {
    return state.getValue();
  }

  function destroy() {
    blockPendingEffects = true;
  }

  return {
    dispatch,
    subscribe,
    getState,
    destroy,
  };
}
