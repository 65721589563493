import { LocalStorageClient } from "./localStorageClient.ts";
import { z } from "zod";
import { Transaction } from "../../logic/appState.ts";

const schema = {
  institutions: {
    key: "institution",
    parser: z.array(
      z.object({
        id: z.string(),
        name: z.string(),
        logoUrl: z.string(),
        countries: z.array(z.string()),
      }),
    ),
  },
  requisitions: {
    key: "requisition",
    parser: z.array(
      z.object({
        id: z.string(),
        institutionId: z.string(),
        completed: z.boolean(),
        reference: z.string(),
      }),
    ),
  },
  transactions: {
    key: (requisitionId: string) => `transactions_${requisitionId}`,
    parser: z.record(
      z.string(),
      z.object({
        id: z.string(),
        requisitionId: z.string(),
        amount: z.string(),
        date: z.string(),
        description: z.string(),
        source: z.string(),
        status: z.union([z.literal("booked"), z.literal("pending")]),
        category: z.string(),
        institution: z.object({
          id: z.string(),
          name: z.string(),
          logoUrl: z.string(),
        }),
      }),
    ),
  },
};

function getLastUpdatedKey(key: string): string {
  return `${key}_last_updated`;
}

export function LocalStorageClientLive(storage: {
  getItem: (key: string) => string | null;
  setItem: (key: string, value: string) => void;
  removeItem: (key: string) => void;
  clear: () => void;
}): LocalStorageClient {
  function readParseOrClear<T extends z.Schema>(key: string, schema: T): z.infer<T> | null {
    const strigified = storage.getItem(key);
    if (strigified === null) return null;
    try {
      const json: unknown = JSON.parse(strigified);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return schema.parse(json);
    } catch {
      storage.removeItem(key);
      return null;
    }
  }

  function isOlderThan(ageLimitsMilliseconds: number, key: string): boolean {
    const lastUpdated = storage.getItem(getLastUpdatedKey(key));
    return lastUpdated !== null && Date.now() - Number(lastUpdated) > ageLimitsMilliseconds;
  }

  function updateLastUpdated(key: string) {
    storage.setItem(getLastUpdatedKey(key), Date.now().toString());
  }

  const getAllRequisitions = () => {
    const persisted = readParseOrClear(schema.requisitions.key, schema.requisitions.parser);
    return persisted ?? [];
  };

  return {
    clear: () => {
      storage.clear();
    },
    requisitions: {
      complete: (requisitionId) => {
        const requisitions = getAllRequisitions();
        for (const r of requisitions) {
          if (r.id === requisitionId) {
            r.completed = true;
          }
        }
        storage.setItem(schema.requisitions.key, JSON.stringify(requisitions));
      },
      getAllCompleted: () => {
        const requisitions = getAllRequisitions();
        return requisitions.filter((r) => r.completed);
      },
      getByReference: (reference) => {
        const requisitions = getAllRequisitions();
        return requisitions.find((r) => r.reference === reference) ?? null;
      },
      addUnique: (requisition) => {
        const requisitions = getAllRequisitions();
        if (requisitions.map(({ id }) => id).includes(requisition.id)) return;
        requisitions.push(requisition);
        storage.setItem(schema.requisitions.key, JSON.stringify(requisitions));
      },
    },
    institutions: {
      getAll: () => {
        return readParseOrClear(schema.institutions.key, schema.institutions.parser);
      },
      saveAll: (institutions) => {
        storage.setItem(schema.institutions.key, JSON.stringify(institutions));
        updateLastUpdated(schema.institutions.key);
      },
      removeIfOlderThan: (ageLimitMilliseconds) => {
        if (isOlderThan(ageLimitMilliseconds, schema.institutions.key)) {
          storage.removeItem(schema.institutions.key);
        }
      },
    },
    transactions: {
      getByRequisitionId: (requisitionId) => {
        const persisted = readParseOrClear(schema.transactions.key(requisitionId), schema.transactions.parser);
        if (persisted === null) return null;
        const result: Record<string, Transaction> = {};
        Object.entries(persisted).forEach(([key, t]) => {
          result[key] = { ...t, date: new Date(t.date) };
        });
        return result;
      },
      saveByRequisitionId: (transactions, requisitionId) => {
        storage.setItem(schema.transactions.key(requisitionId), JSON.stringify(transactions));
        updateLastUpdated(schema.transactions.key(requisitionId));
      },
      setTransactionCategory: (transactionId, requisitionId, category) => {
        const persistedTransactions = readParseOrClear(
          schema.transactions.key(requisitionId),
          schema.transactions.parser,
        );
        if (persistedTransactions) {
          const transaction = persistedTransactions[transactionId];
          if (transaction) {
            transaction.category = category;
            persistedTransactions[transactionId] = transaction;
            storage.setItem(schema.transactions.key(requisitionId), JSON.stringify(persistedTransactions));
          }
        }
      },
      removeByRequisitionIdIfOlderThan: (requisitionId, ageLimitMilliseconds) => {
        if (isOlderThan(ageLimitMilliseconds, schema.transactions.key(requisitionId))) {
          storage.removeItem(schema.transactions.key(requisitionId));
        }
      },
    },
  };
}
