import ReactDOM from "react-dom/client";

import "@radix-ui/themes/styles.css";
import { LocalStorageClientLive } from "./storage/localStorage/localStorageClientLive.ts";
import { StoreLive } from "./logic/store.ts";
import { AppState, getInitialState } from "./logic/appState.ts";
import { AppAction } from "./logic/appAction.ts";
import { appReducer } from "./logic/appReducer.ts";
import { MonetaClientLive } from "./services/moneta/monetaClientLive.ts";
import { App, routerConfig } from "./ui/App.tsx";
import { RouterClient } from "./router/routerClient.ts";
import React from "react";
import { LocalStorageClient } from "./storage/localStorage/localStorageClient.ts";
import { MonetaClient } from "./services/moneta/monetaClient.ts";
import { setStore } from "./ui/store/getStore.ts";
import { GoogleSignInClientLive } from "./services/google/googleSignInClientLive.ts";
import { GoogleSignInClient } from "./services/google/googleSignInClient.ts";
import { configSchema } from "./config/config.ts";
import { ExportManager } from "./export/exportManager.ts";
import { ExportManagerLive } from "./export/exportManagerLive.ts";
import { createBrowserRouter } from "react-router-dom";

const config = configSchema.parse(import.meta.env);
const localStorageClient = LocalStorageClientLive(localStorage);
const monetaClient = MonetaClientLive();
const router = createBrowserRouter(routerConfig);
const routerClient: RouterClient = {
  // eslint-disable-next-line @typescript-eslint/require-await
  navigateTo: async (url: string) => {
    if (router.window) {
      router.window.location.href = url;
    }
  },
  navigateToLoginPage: async () => {
    await router.navigate("/login");
  },
  navigateToHomePage: async () => {
    await router.navigate("/");
  },
  navigateToMyInstitutionsPage: async () => {
    await router.navigate("/my-institutions");
  },
  navigateToInstitutionsPage: async () => {
    await router.navigate("/institutions");
  },
  navigateBack: async () => {
    await router.navigate(-1);
  },
  getQueryParams: () => Object.fromEntries(new URLSearchParams(router.state.location.search)),
};
const googleSignInClient = GoogleSignInClientLive({ clientId: config.VITE_GOOGLE_SIGN_IN_CLIENT_ID });
const exportManager = ExportManagerLive();
const store = StoreLive<
  AppState,
  AppAction,
  {
    localStorageClient: LocalStorageClient;
    monetaClient: MonetaClient;
    routerClient: RouterClient;
    googleSignInClient: GoogleSignInClient;
    exportManager: ExportManager;
  }
>({
  reducer: appReducer,
  initialState: getInitialState(),
  effectContext: {
    localStorageClient,
    routerClient,
    monetaClient,
    googleSignInClient,
    exportManager,
  },
});
setStore(store);
const root = document.getElementById("root");
if (root) {
  ReactDOM.createRoot(root).render(
    <React.StrictMode>
      <App router={router} />
    </React.StrictMode>,
  );
}
