import { initClient } from "@ts-rest/core";
import { getMonetaServerApi } from "@moneta/api";
import { MonetaClient } from "./monetaClient.ts";
import { Failable, failed } from "@moneta/shared";
import { Institution, Transaction } from "../../logic/appState.ts";

export function MonetaClientLive(): MonetaClient {
  const client = initClient(getMonetaServerApi(), {
    baseUrl: "/api",
    credentials: "include",
  });
  return {
    async getTokenAndEmail(googleCredentials) {
      const response = await client.auth.login({
        body: {
          credentials: googleCredentials,
        },
      });
      return response.status === 200
        ? {
            email: response.body.email,
          }
        : failed("Failed to login");
    },
    async connectInstitutionStart(institutionId): Promise<
      Failable<{
        url: string;
        requisitionId: string;
        reference: string;
      }>
    > {
      const response = await client.institution.connectInstitutionStart({
        body: { institution_id: institutionId },
      });
      return response.status === 200
        ? {
            url: response.body.url,
            requisitionId: response.body.requisitionId,
            reference: response.body.reference,
          }
        : failed("Failed to start connect institution", response);
    },
    async getInstitutions(): Promise<Failable<Institution[]>> {
      const response = await client.institution.getInstitutions();
      return response.status === 200 ? response.body : failed("Failed to retrieve institutions", response);
    },
    async getTransactions(requisitionId: string): Promise<Failable<Record<string, Transaction>>> {
      const response = await client.transaction.getTransactions({
        body: {
          requisition_id: requisitionId,
        },
      });
      return response.status === 200
        ? response.body.reduce<Record<string, Transaction>>((acc, t) => {
            acc[t.id] = { ...t, date: new Date(t.date), requisitionId };
            return acc;
          }, {})
        : failed("Failed to retrieve transactions", response);
    },
    async setTransactionCategory(transactionId: string, category: string): Promise<Failable<void>> {
      const response = await client.transaction.setTransactionCategory({
        body: { category, transaction_id: transactionId },
      });
      if (response.status !== 200) return failed("Failed to set transaction category", response);
    },
  };
}
