import { AppStore } from "../../logic/appStore.ts";

let storeReference: AppStore | null = null;

export function setStore(store: AppStore): void {
  storeReference = store;
}

export function getStore(): AppStore {
  if (storeReference === null) throw new Error("Cannot access store before initialization!");
  return storeReference;
}
