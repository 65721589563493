import { days, hours, isFailed } from "@moneta/shared";
import { MonetaClient } from "../services/moneta/monetaClient.ts";
import { AppState, getInitialState, Transaction } from "./appState.ts";
import { AppAction, EffectActions, LifecycleActions, UIInteractionActions } from "./appAction.ts";
import { Dispatcher, Reducer } from "./store.ts";
import { LocalStorageClient } from "../storage/localStorage/localStorageClient.ts";
import { RouterClient } from "../router/routerClient.ts";
import { GoogleSignInClient } from "../services/google/googleSignInClient.ts";
import { Failed } from "@moneta/shared/src/failable/failable.ts";
import { ExportManager } from "../export/exportManager.ts";
import { getFilteredTransactions } from "./computed.ts";

async function getInstitutionsFromLocalStorageOrRemote(
  dispatch: Dispatcher<EffectActions>,
  localStorageClient: LocalStorageClient,
  monetaClient: MonetaClient,
) {
  localStorageClient.institutions.removeIfOlderThan(days(7));
  let institutions = localStorageClient.institutions.getAll();
  if (!institutions) {
    const response = await monetaClient.getInstitutions();
    if (isFailed(response)) {
      handleIfUnauthorizedError(dispatch, response);
      return [];
    }
    institutions = response;
  }
  dispatch("handleInstitutionsRequestSuccess", { institutions });
  return institutions;
}

function handleIfUnauthorizedError(dispatch: Dispatcher<EffectActions>, failed: Failed) {
  const metadata = failed.context.metadata;
  if (typeof metadata === "object" && metadata !== null && "status" in metadata && metadata.status === 401) {
    dispatch("handleUnauthorizedError");
  }
}

const lifecycleActionsReducer: Reducer<
  AppState,
  LifecycleActions,
  EffectActions,
  {
    localStorageClient: LocalStorageClient;
    monetaClient: MonetaClient;
    googleSignInClient: GoogleSignInClient;
    routerClient: RouterClient;
  }
> = {
  onHomeScreenMount: (state) => {
    state.transactions.loading = true;
    return async ({ dispatch, localStorageClient, monetaClient, routerClient }) => {
      const searchParams = routerClient.getQueryParams();
      if (searchParams.connectInstitutionComplete && searchParams.ref) {
        const requisition = localStorageClient.requisitions.getByReference(searchParams.ref);
        if (requisition !== null) {
          localStorageClient.requisitions.complete(requisition.id);
        }
      }

      const requisitions = localStorageClient.requisitions.getAllCompleted();
      let result: Record<string, Transaction> = {};
      for (const requisition of requisitions) {
        localStorageClient.transactions.removeByRequisitionIdIfOlderThan(requisition.id, hours(12));
        const persistedTransactions = localStorageClient.transactions.getByRequisitionId(requisition.id);
        if (persistedTransactions) {
          result = { ...result, ...persistedTransactions };
        } else {
          const transactionResult = await monetaClient.getTransactions(requisition.id);
          if (isFailed(transactionResult)) {
            handleIfUnauthorizedError(dispatch, transactionResult);
            dispatch("handleTransactionRequestFail");
            return;
          }
          localStorageClient.transactions.saveByRequisitionId(transactionResult, requisition.id);
          result = { ...result, ...transactionResult };
        }
        const institutions = await getInstitutionsFromLocalStorageOrRemote(dispatch, localStorageClient, monetaClient);
        const institution = institutions.find((i) => i.id === requisition.institutionId);
        if (institution) {
          dispatch("handleTransactionsRequestSuccess", { institution });
        }
      }
      dispatch("handleAllTransactionsRequestsSuccess", { transactions: result });
    };
  },
  onInstitutionScreenMount: (state) => {
    state.institutions.loading = true;
    return async ({ dispatch, localStorageClient, monetaClient }) => {
      await getInstitutionsFromLocalStorageOrRemote(dispatch, localStorageClient, monetaClient);
    };
  },
  onLoginScreenMount: (state) => {
    const buttonId = state.config.googleSignIn.buttonId;
    return ({ googleSignInClient, dispatch, monetaClient }) => {
      googleSignInClient.loadScript(buttonId, async (credentialToken) => {
        const response = await monetaClient.getTokenAndEmail(credentialToken);
        if (!isFailed(response)) {
          dispatch("handleLoginSuccess", { email: response.email });
        } else handleIfUnauthorizedError(dispatch, response);
      });
    };
  },
  onLoginScreenUnmount: () => {
    return ({ googleSignInClient }) => {
      googleSignInClient.cleanupScript();
    };
  },
};

const uiInteractionActionsReducer: Reducer<
  AppState,
  UIInteractionActions,
  EffectActions,
  {
    monetaClient: MonetaClient;
    localStorageClient: LocalStorageClient;
    routerClient: RouterClient;
    exportManager: ExportManager;
  }
> = {
  didTapTransactionCategory: (_, action) => {
    return async ({ dispatch, monetaClient, localStorageClient }) => {
      const result = await monetaClient.setTransactionCategory(action.payload.transactionId, action.payload.category);
      if (isFailed(result)) {
        handleIfUnauthorizedError(dispatch, result);
      } else {
        localStorageClient.transactions.setTransactionCategory(
          action.payload.transactionId,
          action.payload.requisitionId,
          action.payload.category,
        );
        dispatch("handleSetCategoryRequestSuccess", {
          transactionId: action.payload.transactionId,
          category: action.payload.category,
        });
      }
    };
  },
  didTapInstitutionListItem: (_, action) => {
    return async ({ dispatch, monetaClient, localStorageClient, routerClient }) => {
      const result = await monetaClient.connectInstitutionStart(action.payload.institutionId);
      if (isFailed(result)) {
        handleIfUnauthorizedError(dispatch, result);
        return;
      }
      localStorageClient.requisitions.addUnique({
        id: result.requisitionId,
        completed: false,
        reference: result.reference,
        institutionId: action.payload.institutionId,
      });
      await routerClient.navigateTo(result.url);
    };
  },
  didTapConnectedInstitution: (state, action) => {
    const institution = state.institutions.connected.find((i) => i.id === action.payload.institutionId);
    if (!institution) return;
    for (let i = 0; i < state.home.filters.selectedInstitutions.length; i++) {
      const selectedInstitution = state.home.filters.selectedInstitutions[i];
      if (!selectedInstitution) return;
      if (selectedInstitution.id === action.payload.institutionId) {
        state.home.filters.selectedInstitutions.splice(i, 1);
        return;
      }
    }
    state.home.filters.selectedInstitutions.push(institution);
  },
  didTapExportButton: (state) => {
    const csvContent = [
      ["date", "description", "category", "amount"],
      ...getFilteredTransactions(state).map((t) => [
        t.date.toISOString(),
        t.description,
        t.category,
        t.amount,
        t.institution.name,
      ]),
    ]
      .map((row) => row.join(","))
      .join("\n");
    return ({ exportManager }) => {
      exportManager.exportCSV(csvContent);
    };
  },
  didChangeInstitutionsQuery: (state, action) => {
    state.institutions.query = action.payload.query;
  },
  didTapHomeFiltersToggle: (state) => {
    state.home.filters.isExpanded = !state.home.filters.isExpanded;
  },
  didChangeTransactionsFilterFromDate: (state, action) => {
    state.home.filters.dateFrom = action.payload.date;
  },
  didChangeTransactionsFilterToDate: (state, action) => {
    state.home.filters.dateTo = action.payload.date;
  },
  didTapConnectedInstitutionOption: () => {
    return async ({ routerClient }) => {
      await routerClient.navigateToMyInstitutionsPage();
    };
  },
  didTapAddInstitutionButton: () => {
    return async ({ routerClient }) => {
      await routerClient.navigateToInstitutionsPage();
    };
  },
  didTapBackButton: () => {
    return async ({ routerClient }) => {
      await routerClient.navigateBack();
    };
  },
};

const effectActionsReducer: Reducer<
  AppState,
  EffectActions,
  EffectActions,
  {
    localStorageClient: LocalStorageClient;
    monetaClient: MonetaClient;
    routerClient: RouterClient;
  }
> = {
  handleTransactionRequestFail: (state) => {
    state.transactions.loading = false;
  },
  handleAllTransactionsRequestsSuccess: (state, action) => {
    state.transactions.loading = false;
    state.transactions.data = action.payload.transactions;
  },
  handleSetCategoryRequestSuccess: (state, action) => {
    const transaction = state.transactions.data[action.payload.transactionId];
    if (transaction) {
      transaction.category = action.payload.category;
    }
  },
  handleInstitutionsRequestSuccess: (state, action) => {
    state.institutions.loading = false;
    state.institutions.data = action.payload.institutions;
    return ({ localStorageClient }) => {
      localStorageClient.institutions.saveAll(action.payload.institutions);
    };
  },
  handleTransactionsRequestSuccess: (state, action) => {
    if (!state.institutions.connected.some((i) => i.id === action.payload.institution.id)) {
      state.institutions.connected.push(action.payload.institution);
    }
  },
  handleLoginSuccess: (state, action) => {
    state.auth.email = action.payload.email;
    return async ({ routerClient }) => {
      await routerClient.navigateToHomePage();
    };
  },
  handleUnauthorizedError: (state) => {
    Object.assign(state, getInitialState());
    return async ({ routerClient, localStorageClient }) => {
      localStorageClient.clear();
      await routerClient.navigateToLoginPage();
    };
  },
};

export const appReducer: Reducer<
  AppState,
  AppAction,
  EffectActions,
  {
    monetaClient: MonetaClient;
    localStorageClient: LocalStorageClient;
    routerClient: RouterClient;
    googleSignInClient: GoogleSignInClient;
    exportManager: ExportManager;
  }
> = {
  ...lifecycleActionsReducer,
  ...uiInteractionActionsReducer,
  ...effectActionsReducer,
};
