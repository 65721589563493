import { Button } from "../shared/button.tsx";
import { Check, ChevronDown, Download, Filter } from "lucide-react";
import { useStore } from "../store/useStore.ts";
import { Card, CardContent } from "../shared/card.tsx";
import { Input } from "../shared/input.tsx";
import { Popover, PopoverContent, PopoverTrigger } from "../shared/popover.tsx";
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from "../shared/command.tsx";
import { cn } from "../shared/utils.ts";
import { Avatar, AvatarFallback, AvatarImage } from "../shared/avatar.tsx";

function formatDateToYMD(date: Date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Adding 1 since getMonth() returns 0-11
  const day = String(date.getDate()).padStart(2, "0");
  return `${year.toString()}-${month}-${day}`;
}

export function TransactionsFilters() {
  const [state, dispatch] = useStore((state) => ({
    isExpanded: state.home.filters.isExpanded,
    selectedInstitutions: state.home.filters.selectedInstitutions,
    connectedInstitutions: state.institutions.connected,
    dateFrom: state.home.filters.dateFrom,
    dateTo: state.home.filters.dateTo,
  }));
  return (
    <div className={"mb-6"}>
      <div className="flex justify-between items-center mb-4">
        <Button
          onClick={() => {
            dispatch("didTapHomeFiltersToggle");
          }}
          variant="outline"
        >
          <Filter className="mr-2 h-4 w-4" />
          {state.isExpanded ? "Hide Filters" : "Show Filters"}{" "}
        </Button>
        <Button
          onClick={() => {
            dispatch("didTapExportButton");
          }}
          variant="outline"
        >
          <Download className="mr-2 h-4 w-4" />
          Export CSV
        </Button>
      </div>

      {state.isExpanded && (
        <Card className="mt-4">
          <CardContent className="p-4">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              <div>
                <label htmlFor="bank-select" className="block text-sm font-medium text-gray-700 mb-1">
                  Banks
                </label>
                <Popover>
                  <PopoverTrigger asChild>
                    <Button
                      variant="outline"
                      role="combobox"
                      aria-expanded={state.isExpanded}
                      className="w-full justify-between"
                    >
                      {state.selectedInstitutions.length > 0
                        ? `${state.selectedInstitutions.length.toString()} selected`
                        : "Select banks..."}
                      <ChevronDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent className="w-full p-0">
                    <Command>
                      <CommandInput placeholder="Search banks..." />
                      <CommandEmpty>No bank found.</CommandEmpty>
                      <CommandGroup>
                        <CommandList>
                          {state.connectedInstitutions.map((bank) => (
                            <CommandItem
                              key={bank.id}
                              onSelect={() => {
                                dispatch("didTapConnectedInstitution", { institutionId: bank.id });
                              }}
                            >
                              <Check
                                className={cn(
                                  "mr-2 h-4 w-4",
                                  state.selectedInstitutions.includes(bank) ? "opacity-100" : "opacity-0",
                                )}
                              />
                              <div className={"flex items-center gap-1"}>
                                <Avatar className={"w-4 h-4"}>
                                  <AvatarImage src={bank.logoUrl} />
                                  <AvatarFallback>Bank</AvatarFallback>
                                </Avatar>
                                {bank.name}
                              </div>
                            </CommandItem>
                          ))}
                        </CommandList>
                      </CommandGroup>
                    </Command>
                  </PopoverContent>
                </Popover>
              </div>
              <div>
                <label htmlFor="start-date" className="block text-sm font-medium text-gray-700 mb-1">
                  Start Date
                </label>
                <Input
                  type="date"
                  id="start-date"
                  value={state.dateFrom ? formatDateToYMD(state.dateFrom) : undefined}
                  onChange={(e) => {
                    dispatch("didChangeTransactionsFilterFromDate", {
                      date: e.target.value === "" ? null : new Date(e.target.value),
                    });
                  }}
                />
              </div>
              <div>
                <label htmlFor="end-date" className="block text-sm font-medium text-gray-700 mb-1">
                  End Date
                </label>
                <Input
                  type="date"
                  id="end-date"
                  value={state.dateTo ? formatDateToYMD(state.dateTo) : undefined}
                  onChange={(e) => {
                    dispatch("didChangeTransactionsFilterToDate", {
                      date: e.target.value === "" ? null : new Date(e.target.value),
                    });
                  }}
                />
              </div>
            </div>
          </CardContent>
        </Card>
      )}
    </div>
  );
}
