import { useEffect } from "react";
import { useStore } from "../store/useStore.ts";
import { TransactionList } from "./transaction-list.tsx";
import { getFilteredTransactions } from "../../logic/computed.ts";
import { Header } from "./header.tsx";

export function HomeScreen() {
  const [state, dispatch] = useStore((s) => ({
    loading: s.transactions.loading,
    transactions: getFilteredTransactions(s).map(({ id }) => ({ id })),
  }));
  useEffect(() => {
    dispatch("onHomeScreenMount");
  }, []);

  return (
    <div>
      <Header />
      <div className="bg-gradient-to-br from-gray-100 to-gray-200 py-8 px-4 sm:px-6 lg:px-8">
        <div className="text-3xl mb-6 bg-gradient-to-r from-purple-600 to-pink-600 text-transparent bg-clip-text">
          Transactions
        </div>
        <TransactionList transactionIds={state.transactions} />
      </div>
    </div>
  );
}
