import { Card, CardContent } from "../shared/card.tsx";
import { Calendar, Check } from "lucide-react";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "../shared/tooltip.tsx";
import { useStore } from "../store/useStore.ts";
import { Avatar, AvatarFallback, AvatarImage } from "../shared/avatar.tsx";
import { useState } from "react";
import { TransactionsFilters } from "./TransactionsFilters.tsx";
import { Popover, PopoverClose, PopoverContent, PopoverTrigger } from "../shared/popover.tsx";
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from "../shared/command.tsx";
import { cn } from "../shared/utils.ts";

const GradientTag = ({ filled }: { filled: boolean }) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20.59 13.41l-7.17 7.17a2 2 0 0 1-2.83 0L2 12V2h10l8.59 8.59a2 2 0 0 1 0 2.82z"
      fill={filled ? "url(#gradient)" : "none"}
      stroke="url(#gradient)"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <defs>
      <linearGradient id="gradient" x1="2" y1="2" x2="22" y2="22" gradientUnits="userSpaceOnUse">
        <stop stopColor="#db2777" />
        <stop offset="1" stopColor="#9333ea" />
      </linearGradient>
    </defs>
  </svg>
);

function TransactionItem({ id }: { id: string }) {
  const [transaction, dispatch] = useStore((s) => s.transactions.data[id]);
  const canHover = matchMedia("(hover: hover)").matches;
  const [open, setOpen] = useState<boolean>(false);
  if (!transaction) return null;
  return (
    <Card
      key={id}
      className="backdrop-blur-lg bg-white bg-opacity-50 hover:bg-opacity-70 transition-all duration-300 shadow-md hover:shadow-lg"
    >
      <CardContent className="p-3">
        <div className="grid grid-cols-12 gap-2 items-center">
          <TooltipProvider delayDuration={canHover ? undefined : 0}>
            <Tooltip open={open} onOpenChange={setOpen}>
              <div className="max-sm:col-span-7 max-sm:text-sm col-span-8 overflow-hidden">
                <TooltipTrigger
                  asChild
                  onClick={(e) => {
                    if (!canHover) {
                      e.preventDefault();
                      setOpen(true);
                    }
                  }}
                >
                  <p className="font-medium text-gray-900 truncate">{transaction.description}</p>
                </TooltipTrigger>
                <div className={"flex gap-1 items-center"}>
                  <Avatar className={"w-4 h-4"}>
                    <AvatarImage src={transaction.institution.logoUrl} />
                    <AvatarFallback>Bank</AvatarFallback>
                  </Avatar>
                  <Popover>
                    <PopoverTrigger asChild>
                      <div data-testid={"category-icon"}>
                        <div className={"w-3 h-3"}>
                          <GradientTag filled={transaction.category !== "unknown"} />
                        </div>
                      </div>
                    </PopoverTrigger>
                    <PopoverContent className="w-full p-0 mx-2">
                      <Command>
                        <CommandInput placeholder="Search categories..." />
                        <CommandEmpty>No category found.</CommandEmpty>
                        <CommandGroup>
                          <CommandList>
                            {["Food", "Lifestyle", "House"].map((category) => (
                              <CommandItem
                                key={category}
                                onSelect={() => {
                                  dispatch("didTapTransactionCategory", {
                                    category,
                                    transactionId: transaction.id,
                                    requisitionId: transaction.requisitionId,
                                  });
                                }}
                              >
                                <PopoverClose>
                                  <div className={"flex"}>
                                    <Check
                                      className={cn(
                                        "mr-2 h-4 w-4",
                                        transaction.category === category ? "opacity-100" : "opacity-0",
                                      )}
                                    />
                                    <div className={"flex items-center gap-1"}>{category}</div>
                                  </div>
                                </PopoverClose>
                              </CommandItem>
                            ))}
                          </CommandList>
                        </CommandGroup>
                      </Command>
                    </PopoverContent>
                  </Popover>
                  {transaction.category !== "unknown" && (
                    <span className="truncate max-sm:text-xs text-sm text-gray-500">{transaction.category}</span>
                  )}
                </div>
              </div>
              <TooltipContent>
                <p>{transaction.description}</p>
                <p className="text-gray-300">{transaction.institution.name}</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
          <div className="col-span-2 flex max-sm:text-xs items-center text-sm text-gray-500 justify-center text-center">
            <Calendar className="max-sm:hidden  w-4 h-4 mr-1 flex-shrink-0" />
            <span>
              {new Date(transaction.date).toLocaleDateString("en-US", {
                month: "short",
                day: "numeric",
              })}
            </span>
          </div>
          <p className="truncate max-sm:text-sm max-sm:col-span-3 col-span-2 font-semibold text-gray-900 text-right text-md">
            {transaction.amount + "€"}
          </p>
        </div>
      </CardContent>
    </Card>
  );
}

export function TransactionList({ transactionIds }: { transactionIds: { id: string }[] }) {
  return (
    <>
      <TransactionsFilters />
      <div className="max-w-4xl mx-auto">
        <div className="space-y-3">
          {transactionIds.map(({ id }) => (
            <TransactionItem id={id} />
          ))}
        </div>
      </div>
    </>
  );
}
