import { Button } from "../shared/button.tsx";
import { Avatar, AvatarFallback, AvatarImage } from "../shared/avatar.tsx";
import { Building, ChevronDown, ChevronRight, LogOut } from "lucide-react";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "../shared/dropdown-menu.tsx";
import { getStore } from "../store/getStore.ts";

export function AccountWidget({ avatarUrl, email }: { avatarUrl: string; email: string }) {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          variant="ghost"
          className="justify-start px-2 py-1 h-auto hover:bg-gray-100 dark:hover:bg-gray-800 transition-colors"
        >
          <div className="flex items-center">
            <Avatar className="h-8 w-8 mr-2">
              <AvatarImage src={avatarUrl} alt={email} />
              <AvatarFallback>{(email[0] ?? "A").toUpperCase()}</AvatarFallback>
            </Avatar>
            <div className={"flex max-sm:hidden"}>
              <div className="flex-grow text-left">
                <p className="text-sm font-medium text-gray-900 dark:text-gray-100">{email}</p>
                <p className="text-xs text-gray-500 dark:text-gray-400">View Account</p>
              </div>
              <ChevronRight className="h-4 w-4 text-gray-400" />
            </div>
            <ChevronDown className="h-4 w-4 text-gray-400" />
          </div>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="start" className="w-56">
        <DropdownMenuItem
          onClick={() => {
            getStore().dispatch("didTapConnectedInstitutionOption");
          }}
        >
          <Building className="mr-2 h-4 w-4" />
          <span>Connected Institutions</span>
        </DropdownMenuItem>
        <DropdownMenuItem onClick={() => {}}>
          <LogOut className="mr-2 h-4 w-4" />
          <span>Logout</span>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
