import { AccountWidget } from "./account-widget.tsx";
import logoUrl from "../../../asset/images/coins.webp";
import { Avatar, AvatarFallback, AvatarImage } from "../shared/avatar.tsx";

export function Header() {
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="grid grid-cols-12 h-14">
        <div className="col-span-4 content-center">
          <AccountWidget email={"lollo@mail.com"} avatarUrl={""} />
        </div>
        <div className="col-span-4 content-center overflow-hidden">
          <Avatar className="mx-auto">
            <AvatarImage src={logoUrl} alt={"logo"} />
            <AvatarFallback>{"M"}</AvatarFallback>
          </Avatar>
        </div>
      </div>
    </div>
  );
}
