import { ExportManager } from "./exportManager.ts";

export function ExportManagerLive(): ExportManager {
  return {
    exportCSV: (content) => {
      const blob = new Blob([content], { type: "text/csv" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "data.csv";
      document.body.appendChild(link);
      link.click();
      URL.revokeObjectURL(url);
      document.body.removeChild(link);
    },
  };
}
