import { GoogleSignInClient } from "./googleSignInClient.ts";

export function GoogleSignInClientLive(config: { clientId: string }): GoogleSignInClient {
  let loadedScript: null | HTMLScriptElement = null;

  function cleanupScript() {
    if (loadedScript !== null) {
      document.body.removeChild(loadedScript);
      loadedScript = null;
    }
  }

  return {
    loadScript(signInButtonId, onLoginSuccess) {
      cleanupScript();
      loadedScript = document.createElement("script");
      loadedScript.src = "https://accounts.google.com/gsi/client";
      loadedScript.async = true;
      loadedScript.onload = () => {
        google.accounts.id.initialize({
          client_id: config.clientId,
          callback: (response) => {
            void onLoginSuccess(response.credential);
          },
        });
        const button = document.getElementById(signInButtonId);
        if (button) {
          google.accounts.id.renderButton(button, {
            theme: "outline",
            size: "large",
            type: "standard",
          });
        }
      };
      document.body.appendChild(loadedScript);
    },
    cleanupScript: cleanupScript,
  };
}
